import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../../context/ThemeContext";
import useTitle from "../../../../hooks/useTitle";
import ElectricReportRightSectionMain from "./ElectricReportRightSectionMain";
import { useGetAcrelMeterListQuery } from "../../../../features/acrel/realtimeDashboard/acrelRealtimeDashboardApi";

function ElectricReport() {
  useTitle("Electric Report");
  const theme = useContext(ThemeContext);
  const { data: meterList, isLoading } = useGetAcrelMeterListQuery();
  const [selectedId, setSelectedId] = useState(meterList?.data[0]?.combined_id);
  const [selectedMeters, setSelectedMeters] = useState([
    meterList?.data[0]?.combined_id,
  ]);
  useEffect(() => {
    setSelectedMeters([meterList?.data[0]?.combined_id]);
  }, [isLoading]);

  console.log(selectedMeters)

  return (
    <div className={`container-fluid ${theme}`}>
      <div className="row">
        <div className="col-md-2 left-portion">
          <div className="left-section card-color body-text">
            <div className="in-house-section header-color">
              <div className="in-house-text">In House</div>
              <button className="switch-btn btn btn-info">Switch</button>
            </div>
            <div style={{ paddingLeft: "10px" }}>
              {/* Add a separate checkbox for "select all" */}
              <input
                type="checkbox"
                checked={selectedMeters.length === meterList?.data?.length}
                onChange={(e) => {
                  setSelectedMeters(
                    e.target.checked
                      ? meterList?.data?.map((m) => m.combined_id)
                      : []
                  );
                }}
              />
              <label>Select All</label>
            </div>
            {meterList?.data?.map((meterInfo) => {
              const isSelected = selectedMeters.includes(
                meterInfo?.combined_id
              );

              return (
                <div
                  key={meterInfo?.combined_id}
                  style={{
                    paddingLeft: "20px",
                    backgroundColor:
                      theme === "lite"
                        ? isSelected
                          ? "#0E3C98"
                          : "transparent"
                        : isSelected
                        ? "#F0F5FF"
                        : "transparent",
                    color:
                      theme === "lite"
                        ? isSelected
                          ? "#CBD0D3"
                          : "#CBD0D3"
                        : isSelected
                        ? "#18ADA8"
                        : "#575757",
                    display: "flex",
                    alignItems: "center",
                    height: "35px", // Adjust height as needed
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={(e) => {
                      const newSelectedMeters = [...selectedMeters];
                      if (e.target.checked) {
                        newSelectedMeters.push(meterInfo?.combined_id);
                      } else {
                        const index = newSelectedMeters.indexOf(
                          meterInfo?.combined_id
                        );
                        newSelectedMeters.splice(index, 1);
                      }
                      setSelectedMeters(newSelectedMeters);
                    }}
                  />
                  <label style={{ marginLeft: "10px" }}>
                    {meterInfo?.combined_id}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-md-10 right-portion">
          <div className="right-section card-color body-text">
            <div className={`right-section-main`}>
              <ElectricReportRightSectionMain selectedMeters={selectedMeters} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ElectricReport;
