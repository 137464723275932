import { apiSlice } from "../../api/apiSlice";

export const acrelElectricReportApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    electricReportData: builder.mutation({
      query: (data) => ({
        url: "/apiV2/energy-meter/electric-report",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useElectricReportDataMutation } = acrelElectricReportApi;
