import { apiSlice } from "../../api/apiSlice";

export const acrelElectricParameterReportApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    electricParameterReportData: builder.mutation({
      query: (data) => ({
        url: "/apiV2/energy-meter/parameter-report",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useElectricParameterReportDataMutation } =
  acrelElectricParameterReportApi;
