import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useAcrelRealtimeLineChartDataMutation } from "../../../../../features/acrel/realtimeDashboard/acrelRealtimeDashboardApi";

function HistoricalCurve({ meterId }) {
  const today = new Date().toISOString().split("T")[0];
  const [formData, setFormData] = useState({
    meter_id: meterId,
    type: "1",
    startdate: today,
  });
  useEffect(() => {
    setFormData((previousValue) => ({
      ...previousValue,
      meter_id: meterId,
    }));
  }, [meterId]);

  const [graphData, setGraphData] = useState("");

  const [acrelRealtimeLineChartData, { isLoading }] =
    useAcrelRealtimeLineChartDataMutation();

  let label;
  let graphDataSet;
  const lineChartColors = ["#13B65F", "#FF4949", "#DFA406", "#6fbdcf"];
  useEffect(() => {
    acrelRealtimeLineChartData(formData)
      .unwrap()
      .then((payload) => setGraphData(payload))
      .catch((error) => console.log(error?.status));
  }, [formData]);

  const phaseTypes = Object.keys(graphData);

  graphDataSet = phaseTypes.map((ph_names, idx) => {
    return {
      label: ph_names,
      data: graphData[ph_names].map((gData) => gData.value),
      fill: false,
      borderColor: lineChartColors[idx],
      tension: 0.4,
    };
  });

  const graphLabel = phaseTypes.map((key) => {
    return {
      labels: graphData[key].map((labelData) => {
        return labelData.time;
      }),
    };
  });

  label = graphLabel[0];

  let data = {
    labels: [],
    datasets: [],
  };

  if (label) {
    data = {
      labels: label.labels,
      datasets: graphDataSet,
    };
  }

  const dataChangeHandler = (e) => {
    setFormData((previousValue) => ({
      ...previousValue,
      [e.target.name]: e.target.value,
    }));
  };
  // const selectChangeHandler = (e) => {
  //   setFormData((previousValue) => ({
  //     ...previousValue,
  //     [e.target.name]: e.target.value,
  //   }));
  // };

  // const data = {
  //   labels: ["January", "February", "March", "April", "May"],
  //   datasets: [
  //     {
  //       label: "Dataset 1",
  //       data: [65, 59, 80, 81, 56],
  //       fill: false,
  //       borderColor: "red",
  //     },
  //     {
  //       label: "Dataset 2",
  //       data: [45, 39, 60, 71, 46],
  //       fill: false,
  //       borderColor: "blue",
  //     },
  //     {
  //       label: "Dataset 3",
  //       data: [35, 49, 70, 61, 36],
  //       fill: false,
  //       borderColor: "green",
  //     },
  //   ],
  // };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allow height adjustment via container
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-2 date-search-submit-btn">
          <select
            className="form-select"
            aria-label="Default select example"
            value={formData?.type || ""}
            name="type"
            onChange={(e) => {
              dataChangeHandler(e);
            }}
          >
            <option value="1" selected>
              Phase Voltage
            </option>
            <option value="2">Line Voltage</option>
            <option value="3">Phase Current</option>
            <option value="4">Active Power</option>
            <option value="5">Reactive Power</option>
            <option value="6">Inspecting Power</option>
            <option value="7">Power Factor</option>
            <option value="8">Active Energy</option>
            <option value="9">Reactive Energy</option>
          </select>
        </div>
        <div className="form-group col-md-2">
          <label className="body-text">Date</label>
          <input
            type="date"
            className="form-control"
            name="startdate"
            value={formData?.startdate || ""}
            onChange={dataChangeHandler}
          />
        </div>
        {/* <div className="col-md-2 date-search-submit-btn">
          <button type="submit" class="btn btn-primary col-md-12">
            Submit
          </button>
        </div> */}
      </div>
      <div style={{ height: "400px" }}>
        <Line data={data} options={options} />
      </div>
    </div>
  );
}

export default HistoricalCurve;
