import React, { useState } from "react";
import styles from "./style/ToggledTabs.module.css";
import HistoricalCurve from "./ToggledTabs/HistoricalCurve";
import AlarmInformation from "./ToggledTabs/AlarmInformation";

function ToggledTabs({ meterId }) {
  const [activeTab, setActiveTab] = useState("Historical Curve"); // Initialize active tab index

  const handleTabClick = (index) => {
    setActiveTab(index); // Update active tab index when a tab is clicked
  };
  return (
    <div>
      <div>
        <div className={`${styles["tab-buttons"]} tab-buttons`}>
          {/* {["Historical Curve", "Alarm information"].map((index, i) => (
            <button
              key={index}
              onClick={() => handleTabClick(index)}
              className={index === activeTab ? `${styles.active} active` : ""}
              style={{
                borderTop: "1px solid #ccc",
                borderBottom: "1px solid #ccc",
                borderLeft: i === 1 ? "none" : "1px solid #ccc",
                borderRight: i === 0 ? "1px solid #ccc" : "1px solid #ccc", //to keep only one border in between not two
              }}
            >
              {index}
            </button>
          ))} */}
          {["Historical Curve"].map((index, i) => (
            <button
              key={index}
              onClick={() => handleTabClick(index)}
              className={index === activeTab ? `${styles.active} active` : ""}
              style={{
                borderTop: "1px solid #ccc",
                borderBottom: "1px solid #ccc",
                borderLeft: i === 1 ? "none" : "1px solid #ccc",
                borderRight: i === 0 ? "1px solid #ccc" : "1px solid #ccc", //to keep only one border in between not two
              }}
            >
              {index}
            </button>
          ))}
        </div>
        <div className={`${styles["tab-content"]} card-color`}>
          {/* Render content based on active tab */}
          {activeTab === "Historical Curve" && (
            <HistoricalCurve meterId={meterId} />
          )}
          {/* {activeTab === "Alarm information" && <AlarmInformation />} */}
        </div>
      </div>
    </div>
  );
}

export default ToggledTabs;
