import React, { useEffect, useState } from "react";
import { useElectricReportDataMutation } from "../../../../features/acrel/realtimeDashboard/acrelElectricReportApi";

function ElectricReportDataTable({ dataTableFormData }) {
  const [formData, setFormData] = useState(dataTableFormData);
  const [tableData, setTableData] = useState();

  const [electricReportData] = useElectricReportDataMutation();
  useEffect(() => {
    setFormData(dataTableFormData);
  }, [dataTableFormData]);

  useEffect(() => {
    electricReportData(formData)
      .unwrap()
      .then((payload) => setTableData(payload))
      .catch((error) => console.log(error?.status));
  }, [formData]);
  let rows;
  let headers;

  if (tableData) {
    rows = [...tableData];

    headers = rows.length > 0 ? Object.keys(rows[0]) : [];
  }

  return (
    <div
      style={{
        position: "relative",
        overflowX: "auto", // Enable scrolling
        height: "calc(100vh - 150px)", // Set a height for the scrollable area
        width: "100%",
      }}
    >
      <table style={{ width: "100%" }}>
        <thead>
          <tr
            style={{
              position: "sticky",
              top: 0,
              background: "#f4f4f4", // Background color for the header
              zIndex: 2, // Ensure header stays above other rows
              overflow: "hidden",
            }}
          >
            {headers?.map((header, index) => (
              <th
                key={index}
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  position: index === 0 ? "sticky" : "relative",
                  left: index === 0 ? 0 : "auto", // Fixes first column in header
                  background: "#f4f4f4",
                  zIndex: index === 0 ? 3 : "auto", // Ensure sticky first column stays above header
                  minWidth: "150px", // Minimum width to prevent content overlap
                  boxSizing: "border-box",
                  color: "#575757",
                }}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map((row, index) => (
            <tr key={index}>
              {headers.map((header, colIndex) => (
                <td
                  key={colIndex}
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    position: colIndex === 0 ? "sticky" : "relative",
                    left: colIndex === 0 ? 0 : "auto", // Fixes first column
                    background: colIndex === 0 ? "#fff" : "inherit",
                    zIndex: colIndex === 0 ? 1 : "auto",
                    minWidth: "150px", // Minimum width to prevent content overlap
                    boxSizing: "border-box",
                    color: colIndex === 0 ? "#575757" : "",
                  }}
                >
                  {row[header]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ElectricReportDataTable;
