import { apiSlice } from "../../api/apiSlice";

export const acrelDashboardApi = apiSlice.injectEndpoints({
  tagTypes: ["company"],
  endpoints: (builder) => ({
    getAcrelDashboardData: builder.query({
      query: () => "/apiV2/energy-meter/device-status",
      providesTags: ["company"],
    }),
  }),
});

export const { useGetAcrelDashboardDataQuery } = acrelDashboardApi;
