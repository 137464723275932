import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaEdit, FaImage } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { AiFillDelete } from "react-icons/ai";

import { ThemeContext } from "../../../context/ThemeContext";
import { Table, Pagination, Input } from "rsuite";
import getBase64 from "../../../helper/helper";

import { useGetAllBillListQuery } from "../../../features/acrel/billCalculation/acrelBillCalculationApi";
import { useAcrelBillCreateMutation } from "../../../features/acrel/billCalculation/acrelBillCalculationApi";
import { useAcrelBillUpdateMutation } from "../../../features/acrel/billCalculation/acrelBillCalculationApi";
import { useDeleteBillMutation } from "../../../features/acrel/billCalculation/acrelBillCalculationApi";

import { useGetAcrelMeterListQuery } from "../../../features/acrel/realtimeDashboard/acrelRealtimeDashboardApi";
import Swal from "sweetalert2";
import useTitle from "../../../hooks/useTitle";

const { Column, HeaderCell, Cell } = Table;

function BillCalculation() {
  useTitle("Bill Calculation");
  const theme = React.useContext(ThemeContext);
  const [show, setShow] = React.useState(false);
  const [createFormSelector, setCreateFormSelector] = useState(true);
  const [reseponseMessage, setResponseMessage] = useState();
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [updateFormData, setUpdateFormData] = useState({});
  const [tableLoading, setTableLoading] = useState(true);

  const { data: meterList, isLoading: meterListLoading } =
    useGetAcrelMeterListQuery();
  const { data: allBillInfo, isLoading } = useGetAllBillListQuery();
  const [acrelBillCreate] = useAcrelBillCreateMutation();
  const [acrelBillUpdate] = useAcrelBillUpdateMutation();
  const [deleteBill] = useDeleteBillMutation();

  const [imageShow, setImageShow] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  let defaultData = [];
  let data = [];

  useEffect(() => {
    if (!isLoading) {
      setTableLoading(false);
    }
  }, [isLoading]);

  if (!isLoading) {
    if (allBillInfo.success) {
      defaultData = [...allBillInfo?.data];
      defaultData = defaultData?.map((premesisInfo) => {
        const data = new Date(premesisInfo.updated_at);
        const formattedDate = data.toLocaleString();
        return {
          ...premesisInfo,
          updated_at: formattedDate,
        };
      });
    }

    data = defaultData?.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
  }

  // const handleChange = (e) => {
  //   alert(e.target.name);

  //   setFile(e.target.files[0]);
  //   if (file) {
  //     setImageUrl(URL.createObjectURL(file));
  //   } else {
  //     setImageUrl(null);
  //   }
  // };

  // if (updatePremisesResult || result) {
  //   console.log(updatePremisesResult || result);
  // }

  const handleClose = () => {
    setShow(false);
    setImageShow(false);
  };
  const handleModalOutsideClick = () => {
    setShow(false);
    setResponseMessage("");
  };
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();

    if (createFormSelector) {
      acrelBillCreate(updateFormData)
        .unwrap()
        .then((payload) => setResponseMessage(payload?.message))
        .catch((error) => setResponseMessage(error?.status));
    } else {
      acrelBillUpdate(updateFormData)
        .unwrap()
        .then((payload) => setResponseMessage(payload?.message))
        .catch((error) => setResponseMessage(error?.status));
    }
  };
  const handleEdit = (id) => {
    // alert(id);
    let formData = defaultData.find((object) => object.id == id) || {};

    delete formData.image; // as updating empty value keeps previous image on db
    setUpdateFormData(formData);
    // console.log(formData);
  };
  const dataChangeHandler = (e) => {
    setResponseMessage(""); //to hide form submit message on change
    if (e.target) {
      if (e.target.name === "image") {
        getBase64(e.target.files[0])
          .then((base64image) => {
            setUpdateFormData((previousValue) => ({
              ...previousValue,
              [e.target.name]: base64image,
            }));
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setUpdateFormData((previousValue) => ({
          ...previousValue,
          [e.target.name]: e.target.value,
        }));
      }
    } else {
      //inters here because description textarea sends text directly through "e"
      setUpdateFormData((previousValue) => ({
        ...previousValue,
        description: e,
      }));
    }
    // console.log(updateFormData);
  };

  const imageModalOutsideClick = () => {
    setImageShow(false);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBill({ id: id })
          .unwrap()
          .then((payload) => {
            Swal.fire("Deleted!", "Your item has been deleted.", "success");
            console.log(payload?.message);
            // roomSensorMapList()
            //   .unwrap()
            //   .then((payload) => setAllRoomMapList(payload?.data))
            //   .catch((error) => {
            //     console.log(error);
            //   });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
    // alert(id);
  };

  return (
    <div className={`row row-sm ${theme} `}>
      <div
        className="body-text"
        style={{ fontSize: "20px", fontWeight: "bold" }}
      >
        Bill Calculation
      </div>
      <div style={{ textAlign: "right", marginBottom: "1rem" }}>
        <button
          className="btn btn-primary btn-md "
          // style={{ width: "20vw" }}
          onClick={() => {
            setShow(true);
            setCreateFormSelector(true);
            setUpdateFormData({});
          }}
        >
          Bill Create
        </button>
        {/* </div> */}
      </div>
      <Modal
        show={imageShow}
        onHide={handleClose}
        className={`${theme} `}
        onExited={imageModalOutsideClick}
        centered
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>Image</Modal.Title>

            <RxCross2 onClick={imageModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {" "}
            <img
              className="image_inside_modal"
              src={imageUrl}
              alt="modal_image"
            ></img>
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        classname={`${theme} `}
        centered
        onExited={handleModalOutsideClick}
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>
              {createFormSelector ? "Create Bill" : "Update Bill"}
            </Modal.Title>
            <div style={{ color: "white" }}>{reseponseMessage}</div>
            <RxCross2 onClick={handleModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {" "}
            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 zero-padding">
              <div>
                <div>
                  <form onSubmit={formSubmitHandler}>
                    <div class="form-group">
                      <label class="form-label" for="">
                        Select Meter <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        required
                        name="meter_id"
                        class="form-control form-select"
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        defaultValue={updateFormData?.meter_id || ""}
                      >
                        <option label="Choose one"></option>
                        {meterList?.data?.map((meterInfo) => {
                          return (
                            <option value={meterInfo?.id}>
                              {meterInfo?.meter_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="form-group">
                      <label>
                        Omf <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Omf"
                        name="omp"
                        defaultValue={updateFormData?.omp || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Sanction Load <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Sanction Load"
                        name="sanction_load"
                        defaultValue={updateFormData?.sanction_load || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Off Peak Price <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Off Peak Price"
                        name="off_peak_price"
                        defaultValue={updateFormData?.off_peak_price || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        On Peak Price <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="On Peak Price"
                        name="on_peak_price"
                        defaultValue={updateFormData?.on_peak_price || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Demand Charge <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Demand Charge"
                        name="demand_charge"
                        defaultValue={updateFormData?.demand_charge || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>
                        Vat <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Vat"
                        name="vat"
                        defaultValue={updateFormData?.vat || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>
                    <div class="form-group">
                      <label class="form-label" for="">
                        Status <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        required
                        name="status"
                        class="form-control form-select"
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        defaultValue={updateFormData?.status || ""}
                      >
                        <option label="Choose one"></option>

                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Peak Start <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="time"
                            className="form-control"
                            placeholder="Peak Start"
                            name="pick_start"
                            defaultValue={updateFormData?.pick_start || ""}
                            onChange={(e) => {
                              dataChangeHandler(e);
                            }}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Peak End <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="time"
                            className="form-control"
                            placeholder="Peak End"
                            name="pick_end"
                            defaultValue={updateFormData?.pick_end || ""}
                            onChange={(e) => {
                              dataChangeHandler(e);
                            }}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Off Peak Start{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="time"
                            className="form-control"
                            placeholder="Off Peak Start"
                            name="off_pick_start"
                            defaultValue={updateFormData?.off_pick_start || ""}
                            onChange={(e) => {
                              dataChangeHandler(e);
                            }}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Off Peak End <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="time"
                            className="form-control"
                            placeholder="Off Peak End"
                            name="off_pick_end"
                            defaultValue={updateFormData?.off_pick_end || ""}
                            onChange={(e) => {
                              dataChangeHandler(e);
                            }}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="form-group">
                      <label>Description</label>
                      <Input
                        name="description"
                        as="textarea"
                        rows={3}
                        placeholder="Description"
                        defaultValue={updateFormData?.description || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                    </div> */}

                    <div style={{ textAlign: "center" }}>
                      <Button type="submit">Submit</Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <div>
        <div className="table-responsive theme-background theme-text">
          <Table height={500} data={data || []} loading={tableLoading}>
            <Column
              width={50}
              align="center"
              className={`body-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Id.</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column
              width={150}
              align="center"
              flexGrow={1}
              className={`body-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Meter Id </HeaderCell>
              <Cell dataKey="meter_id" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`body-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Meter Name</HeaderCell>
              <Cell dataKey="meter_name" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`body-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Omf</HeaderCell>
              <Cell dataKey="omp" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`body-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>
                Sanction Load
              </HeaderCell>
              <Cell dataKey="sanction_load" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`body-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>
                Off Peak Price
              </HeaderCell>
              <Cell dataKey="off_peak_price" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`body-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>
                On Peak Price
              </HeaderCell>
              <Cell dataKey="on_peak_price" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`body-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>
                Demand Charge
              </HeaderCell>
              <Cell dataKey="demand_charge" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`body-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Vat(%)</HeaderCell>
              <Cell dataKey="vat" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`body-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Status</HeaderCell>
              <Cell dataKey="status_text" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`body-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Peak Start</HeaderCell>
              <Cell dataKey="pick_start" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`body-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Peak End</HeaderCell>
              <Cell dataKey="pick_end" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`body-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>
                Off Peak Start
              </HeaderCell>
              <Cell dataKey="off_pick_start" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`body-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Off Peak End</HeaderCell>
              <Cell dataKey="off_pick_end" />
            </Column>
            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`body-text theme-table-background`}
              fullText
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Last Updated</HeaderCell>
              <Cell dataKey="updated_at" />
            </Column>
            <Column
              width={100}
              align="center"
              // flexGrow={1}
              className={`body-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Edit</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div
                    icon="edit"
                    onClick={() => {
                      setShow(true);
                      setCreateFormSelector(false);
                      handleEdit(rowData.id);
                    }}
                  >
                    <FaEdit />
                  </div>
                )}
              </Cell>
            </Column>
            <Column
              width={100}
              align="center"
              // flexGrow={1}
              className={`body-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Delete</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div
                    icon="delete"
                    onClick={() => {
                      // setShow(true);
                      // setCreateFormSelector(false);
                      handleDelete(rowData.id);
                    }}
                  >
                    <AiFillDelete />
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="lg"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={defaultData.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BillCalculation;
