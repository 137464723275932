import React from "react";
import { Doughnut } from "react-chartjs-2";

const DoughnutChart = ({ chartData }) => {
  // Data for the doughnut chart
  const data = {
    labels: chartData?.label,
    datasets: [
      {
        // label: "My First Dataset",
        data: chartData?.chartData,
        backgroundColor: [
          "rgb(3,173,168)",
          "rgb(157,157,157)",
          "rgb(14,185,246)",
        ],
        borderColor: ["rgb(3,173,168)", "rgb(157,157,157)", "rgb(14,185,246)"],
        borderWidth: 0.5,
      },
    ],
  };

  return <Doughnut data={data} />;
};

export default DoughnutChart;
