import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../../context/ThemeContext";
import useTitle from "../../../../hooks/useTitle";
import ElectricParameterRightSectionMain from "./ElectricParameterRightSectionMain";
import { useGetAcrelMeterListQuery } from "../../../../features/acrel/realtimeDashboard/acrelRealtimeDashboardApi";

function ElectricParameterReport() {
  useTitle("Electric Parameter Report");
  const theme = useContext(ThemeContext);
  const { data: meterList, isLoading } = useGetAcrelMeterListQuery();
  const [selectedId, setSelectedId] = useState(meterList?.data[0]?.combined_id);

  useEffect(() => {
    setSelectedId(meterList?.data[0]?.combined_id);
  }, [isLoading]);

  return (
    <div className={`container-fluid ${theme}`}>
      <div className="row">
        <div className="col-md-2 left-portion">
          <div className="left-section card-color body-text">
            <div className="in-house-section header-color">
              <div className="in-house-text">In House</div>
              <button className="switch-btn btn btn-info">Switch</button>
            </div>
            {meterList?.data?.map((meterInfo) => {
              const isSelected = meterInfo?.combined_id === selectedId;
              return (
                <div
                  key={meterInfo?.combined_id}
                  style={{
                    paddingLeft: "20px",
                    backgroundColor: isSelected
                      ? theme === "lite"
                        ? "#0E3C98"
                        : "#F0F5FF"
                      : theme === "lite"
                      ? "transparent"
                      : "transparent",
                    color: isSelected
                      ? theme === "lite"
                        ? "#CBD0D3"
                        : "#18ADA8"
                      : theme === "lite"
                      ? "#CBD0D3"
                      : "#575757",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    height: "35px", // Adjust height as needed
                  }}
                  onClick={() => setSelectedId(meterInfo?.combined_id)}
                >
                  {meterInfo?.combined_id}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-md-10 right-portion">
          <div className="right-section card-color body-text">
            <div className={`right-section-main `}>
              <ElectricParameterRightSectionMain selectedId={selectedId} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ElectricParameterReport;
