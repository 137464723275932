import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import useTitle from "../../../hooks/useTitle";
import styles from "./styles/bill.module.css";
import { useGetAcrelMeterListQuery } from "../../../features/acrel/realtimeDashboard/acrelRealtimeDashboardApi";
import { useAcrelTotalBillMutation } from "../../../features/acrel/billReport/acrelBillReportApi";

function BillReport() {
  useTitle("Bill Report");
  const theme = useContext(ThemeContext);
  const [formData, setFormData] = useState({});
  const [billData, setBillData] = useState({});

  const { data: meterList } = useGetAcrelMeterListQuery();
  const [acrelTotalBill, { isLoading }] = useAcrelTotalBillMutation();

  useEffect(() => {
    acrelTotalBill()
      .unwrap()
      .then((payload) => setBillData(payload))
      .catch((error) => console.log(error?.status));
  }, []);

  const dataChangeHandler = (e) => {
    setFormData((previousValue) => ({
      ...previousValue,
      [e.target.name]: e.target.value,
    }));
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();

    acrelTotalBill(formData)
      .unwrap()
      .then((payload) => setBillData(payload))
      .catch((error) => console.log(error?.status));
  };

  // console.log(billData);
  return (
    <div className={`${theme}`}>
      <div
        className="body-text"
        style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "1rem" }}
      >
        Data Log
      </div>
      <div className="row">
        <div className="col-md-10">
          <form onSubmit={formSubmitHandler}>
            <div className="row">
              <div className="form-group col-md-2">
                <label className="body-text">Start Date </label>
                <input
                  type="date"
                  className="form-control"
                  name="startdate"
                  // onChange={(e) =>
                  //   setDate({ ...date, startdate: e.target.value })
                  // }
                  onChange={dataChangeHandler}
                  required
                />
              </div>
              <div class="form-group col-md-2">
                <label className="body-text">End Date</label>
                <input
                  type="date"
                  class="form-control"
                  name="enddate"
                  // onChange={(e) =>
                  //   setDate({ ...date, enddate: e.target.value })
                  // }
                  onChange={dataChangeHandler}
                  required
                />
              </div>
              <div className="col-md-2 date-search-submit-btn">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="meter_id"
                  // value={room_id}
                  onChange={dataChangeHandler}
                  required
                >
                  <option value="" selected>
                    Select Meter
                  </option>
                  {meterList?.data?.map((meterInfo) => {
                    return (
                      <option
                        key={meterInfo?.meter_id}
                        value={meterInfo?.meter_id}
                      >
                        {meterInfo?.meter_name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-md-2 date-search-submit-btn">
                <button type="submit" class="btn btn-primary col-md-12">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className={`row `}>
        <div className={`col-md-4 `}>
          <div className={`${styles.card} ${styles.badge}`}>
            <div className={styles.title}>Meter Name</div>
            <div className={styles.value}>{billData?.meter_no}</div>
          </div>
        </div>
        <div className={`col-md-4`}>
          <div className={`${styles.card} ${styles.badge}`}>
            <div className={styles.title}>Billing Month</div>
            <div className={styles.value}>{billData?.billing_month}</div>
          </div>
        </div>
      </div>
      <div className={`row`}>
        <div className={`col-md-4`}>
          <div className={`${styles.card} ${styles.card_data_section}`}>
            <div className={`${styles.card_title}`}>Charge List</div>
            <div className={`${styles.card_table}`}>
              <table style={{ width: "100%" }}>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>OMF</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.charge_list?.OMF}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Sanctioned Load</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.charge_list?.sanctioned_load}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Consumer Class</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.charge_list?.consumer_class}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Off-Peak Price</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.charge_list?.off_peak_price}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>On-Peak Price</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.charge_list?.on_peak_price}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Demand Charge</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.charge_list?.demand_charge}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className={`col-md-4`}>
          <div className={`${styles.card} ${styles.card_data_section}`}>
            <div className={`${styles.card_title}`}>
              Cumulative Demand Reading
            </div>
            <div className={`${styles.card_table}`}>
              <table style={{ width: "100%" }}>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Current Value</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.cumulative_demand_reading?.current_value}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Previous Value</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.cumulative_demand_reading?.previous_value}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Difference</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.cumulative_demand_reading?.difference}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Consumed Unit</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.cumulative_demand_reading?.consumed_unit}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Ex Demand Charge Unit</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.cumulative_demand_reading?.ex_demand_charge_unit}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className={`col-md-4`}>
          <div className={`${styles.card} ${styles.card_data_section}`}>
            <div className={`${styles.card_title}`}>KWH</div>
            <div className={`${styles.card_table}`}>
              <table style={{ width: "100%" }}>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Previous Peak Value</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.KWH?.previous_peak_value}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Current Peak Value</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.KWH?.current_peak_value}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Peak Difference</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.KWH?.peak_difference}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Previous Off-Peak Value</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.KWH?.previous_off_peak_value}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Current Off-Peak Value</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.KWH?.current_off_peak_value}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Off-Peak Difference</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.KWH?.off_peak_difference}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Off-Peak Consumed Unit</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.KWH?.off_peak_consumed_unit}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Peak Consumed Unit</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.KWH?.peak_consumed_unit}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className={`col-md-4`}>
          <div className={`${styles.card} ${styles.card_data_section}`}>
            <div className={`${styles.card_title}`}>Charges List</div>
            <div className={`${styles.card_table}`}>
              <table style={{ width: "100%" }}>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Off-Peak Energy Charge</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.charges_list?.off_peak_energy_charge}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Peak Energy Charge</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.charges_list?.peak_energy_charge}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Demand Charge</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.charges_list?.demand_charge}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Minimum Charge</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.charges_list?.minimum_charge}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Service Charge</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.charges_list?.service_charge}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Transformer Loss</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.charges_list?.transformer_loss}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Transformer Rent</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.charges_list?.transformer_rent}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>PFC Charge</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.charges_list?.pfc_charge}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>VAT</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.charges_list?.vat}
                  </td>
                </tr>
                <tr className={`${styles.tr}`}>
                  <td className={`${styles.td}`}>Total</td>
                  <td className={`${styles.td_text_right} ${styles.td}`}>
                    {billData?.charges_list?.total}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BillReport;
