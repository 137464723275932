import { apiSlice } from "../../api/apiSlice";

export const acrelBillReportApi = apiSlice.injectEndpoints({
  // tagTypes: ["Bill"],
  endpoints: (builder) => ({
    // getAllBillList: builder.query({
    //   query: () => "/apiV2/energy-meter/bill-info",
    //   providesTags: ["Bill"],
    // }),

    acrelTotalBill: builder.mutation({
      query: (data) => ({
        url: "/apiV2/energy-meter/total-bill",
        method: "POST",
        body: data,
      }),
      // invalidatesTags: ["Total-Bill"],
    }),
    // acrelBillUpdate: builder.mutation({
    //   query: (data) => ({
    //     url: "/apiV2/energy-meter/bill-update",
    //     method: "POST",
    //     body: data,
    //   }),
    //   invalidatesTags: ["Bill"],
    // }),
    // deleteBill: builder.mutation({
    //   query: ({ id }) => ({
    //     url: `/apiV2/energy-meter/bill-destroy?id=${id}`,
    //     method: "DELETE",
    //     // body: data,
    //   }),
    //   invalidatesTags: ["Bill"],
    // }),
  }),
});

export const {
  // useGetAllBillListQuery,
  useAcrelTotalBillMutation,
  // useAcrelBillUpdateMutation,
  // useDeleteBillMutation,
} = acrelBillReportApi;
