import React, { useEffect, useState } from "react";
import { useElectricParameterReportDataMutation } from "../../../../features/acrel/realtimeDashboard/acrelElectricParameterReportApi";

function ElectricParameterDataTable({ tableFormData }) {
  const [formData, setFormData] = useState(tableFormData);
  const [tableData, setTableData] = useState();
  const [electricParameterReportData, { isLoading }] =
    useElectricParameterReportDataMutation();

  useEffect(() => {
    setFormData(tableFormData);
  }, [tableFormData]);
  // console.log(formData);
  useEffect(() => {
    electricParameterReportData(formData)
      .unwrap()
      .then((payload) => setTableData(payload))
      .catch((error) => console.log(error?.status));
  }, [formData]);

  let aggregates;
  let rows;
  let headers;
  let aggregatesKeys;

  if (tableData) {
    aggregates = tableData?.find((item) => item.interval === "Aggregates");

    rows = tableData?.filter((item) => item.interval !== "Aggregates");

    headers = rows.length > 0 ? Object.keys(rows[0]) : [];

    aggregatesKeys = Object.keys(aggregates).filter(
      (key) => key !== "interval"
    );
  }
  const subkeys = ["min", "max", "avg"];

  return (
    <div
      style={{
        position: "relative",
        overflowX: "auto", // Enable scrolling
        height: "calc(100vh - 150px)", // Set a height for the scrollable area
        width: "100%",
      }}
    >
      <table
        style={{
          borderCollapse: "collapse",
          width: "100%",
          tableLayout: "auto", // Automatically adjusts column widths based on content
        }}
      >
        <thead>
          <tr
            style={{
              position: "sticky",
              top: 0,
              background: "#f4f4f4", // Background color for the header
              zIndex: 2, // Ensure header stays above other rows
              overflow: "hidden",
            }}
          >
            {headers?.map((header, index) => (
              <th
                key={index}
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  position: index === 0 ? "sticky" : "relative",
                  left: index === 0 ? 0 : "auto", // Fixes first column in header
                  background: "#f4f4f4",
                  zIndex: index === 0 ? 3 : "auto", // Ensure sticky first column stays above header
                  minWidth: "150px", // Minimum width to prevent content overlap
                  boxSizing: "border-box",
                  color: "#575757",
                }}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((header, colIndex) => (
                <td
                  key={colIndex}
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    position: colIndex === 0 ? "sticky" : "relative",
                    left: colIndex === 0 ? 0 : "auto", // Fixes first column
                    background: colIndex === 0 ? "#fff" : "inherit",
                    zIndex: colIndex === 0 ? 1 : "auto",
                    minWidth: "150px", // Minimum width to prevent content overlap
                    boxSizing: "border-box",
                    color: colIndex === 0 ? "#575757" : "",
                  }}
                >
                  {row[header]}
                </td>
              ))}
            </tr>
          ))}
          {subkeys.map((subkey, subkeyIndex) => (
            <tr key={subkeyIndex}>
              <td
                style={{
                  border: "1px solid #ddd",
                  padding: "8px",
                  position: "sticky",
                  left: 0,
                  background: "#fff",
                  zIndex: 2,
                  minWidth: "150px", // Ensure it matches the first column width
                  boxSizing: "border-box",
                  color: subkeyIndex === 0 ? "#575757" : "#575757",
                }}
              >
                {subkey}
              </td>
              {aggregatesKeys?.map((key, keyIndex) => (
                <td
                  key={keyIndex}
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    minWidth: "150px", // Ensure consistency in width
                    boxSizing: "border-box",
                  }}
                >
                  {aggregates[key][subkey]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ElectricParameterDataTable;
