import { apiSlice } from "../../api/apiSlice";

export const acrelBillCalculationApi = apiSlice.injectEndpoints({
  tagTypes: ["Bill"],
  endpoints: (builder) => ({
    getAllBillList: builder.query({
      query: () => "/apiV2/energy-meter/bill-info",
      providesTags: ["Bill"],
    }),

    acrelBillCreate: builder.mutation({
      query: (data) => ({
        url: "/apiV2/energy-meter/bill-setup",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Bill"],
    }),
    acrelBillUpdate: builder.mutation({
      query: (data) => ({
        url: "/apiV2/energy-meter/bill-update",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Bill"],
    }),
    deleteBill: builder.mutation({
      query: ({ id }) => ({
        url: `/apiV2/energy-meter/bill-destroy?id=${id}`,
        method: "DELETE",
        // body: data,
      }),
      invalidatesTags: ["Bill"],
    }),
  }),
});

export const {
  useGetAllBillListQuery,
  useAcrelBillCreateMutation,
  useAcrelBillUpdateMutation,
  useDeleteBillMutation,
} = acrelBillCalculationApi;
